<script lang="ts" setup>
interface Props {
  urlPrefix?: string
  itemsPerPage?: number
}

const { urlPrefix, itemsPerPage } = defineProps<Props>()
const { t } = useI18n()

const { cities, paginatorInfo, next, prev } = await useCities2({
  fetchOperation: 'FetchCities',
  urlPrefix,
  preselected: {},
  first: itemsPerPage,
})

defineOptions({
  name: 'CatalogCities',
})
</script>

<template>
  <div data-catalog-cities class="space-y-6">
    <KCarousel container-classes="gap-2" :theme="BUTTON_THEME.SECONDARY">
      <FiltersSearch
        :placeholder="t('searchPlaceholder')"
        :collapse="false"
        :url-prefix
      />
    </KCarousel>

    <CityListVertical :cities :paginator-info :url-prefix :next :prev />
  </div>
</template>

<i18n>
de:
  searchPlaceholder: "Stadt/PLZ suchen"
es:
  searchPlaceholder: "Buscar ciudad/código postal"
</i18n>
